











































































import {
  defineComponent,
  ref,
  useContext,
  PropType,
  computed,
} from '@nuxtjs/composition-api';
import { useContactForm } from '@wemade-vsf/contact';
import { useUiNotification } from '@wemade-vsf/composables';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { SfModal, SfInput, SfTextarea, SfButton, SfLoader, SfHeading, SfQuantitySelector } from '@storefront-ui/vue';
import { EmailTypeEnum } from '@wemade-vsf/magento-api';
import { getProductSku } from '@wemade-vsf/catalog/lib/runtime/product/getters/productGetters';
import type { Product } from '@wemade-vsf/catalog/lib/runtime/product/types';

extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

export default defineComponent({
  name: 'PriceRequestModal',
  components: {
    SfModal,
    SfHeading,
    ValidationObserver,
    ValidationProvider,
    SfInput,
    SfTextarea,
    SfButton,
    SfLoader,
    SfQuantitySelector
  },
  props: {
    product: {
      type: Object as PropType<Product>
    }
  },
  setup (props, { emit }) {
    const { i18n } = useContext();
    const { send } = useUiNotification();

    function close() {
      emit('close');
    }
    const form = ref({
      name: '',
      company: '',
      email: '',
      qty: 1,
      message: ''
    });

    const additionalInfoText = i18n.t('priceRequestAdditional');
    const additionalInfo = additionalInfoText === 'priceRequestAdditional' ? '' : additionalInfoText

    const sku = computed(() => props.product ? getProductSku(props.product) : '');

    const { sendEmail, loading, error } = useContactForm();

    async function onSubmit() {
      const params = {
        senderEmail: form.value.email,
        subject: i18n.t('New Price Request'),
        htmlBody: `
        ${i18n.t('You received a new price request')}<br /><br />
        <table width="100%">
          <tr>
            <td>${i18n.t('Name')}</td>
            <td>${form.value.name}</td>
          </tr>
          <tr>
            <td>${i18n.t('Company')}</td>
            <td>${form.value.company}</td>
          </tr>
          <tr>
            <td>${i18n.t('Email address')}</td>
            <td>${form.value.email}</td>
          </tr>
          <tr>
            <td>${i18n.t('Product')}</td>
            <td>${i18n.t('SKU')}: ${sku.value}<br />
                ${props.product?.name || ''}
            </td>
          </tr>
          <tr>
            <td>${i18n.t('Quantity')}</td>
            <td>${form.value.qty}</td>
          </tr>
        </table>
        `,
        type: EmailTypeEnum.PRICE_REQUEST,
        sendNotification: true
      }
      const result = await sendEmail(params)
      if (!error.value.sendEmail && result) {
        send({
          type: 'success',
          message: i18n.t('Your request has been sent'),
          icon: 'success',
          id: Symbol('sendPriceRequest'),
          persist: false
        })
        close()
      }
    }

    return {
      form,
      sku,
      additionalInfo,
      close,
      loading,
      onSubmit
    }
  }
})
