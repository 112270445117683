var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SfModal',{staticClass:"price-request-modal",attrs:{"cross":true,"visible":true},on:{"close":_vm.close}},[_c('SfHeading',{attrs:{"level":3,"title":_vm.$t('Price Request')}}),_vm._v(" "),(_vm.additionalInfo)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.additionalInfo)}}):_vm._e(),_vm._v(" "),_c('ValidationObserver',{staticClass:"form",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"grid is-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"name","required":"","label":_vm.$t('Name'),"valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"company","required":"","label":_vm.$t('Company'),"valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.company),callback:function ($$v) {_vm.$set(_vm.form, "company", $$v)},expression:"form.company"}})]}}],null,true)})],1),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"email","required":"","label":_vm.$t('Email address'),"valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"product-info"},[_c('span',{staticClass:"sku"},[_c('strong',[_vm._v(_vm._s(_vm.$t('Product SKU'))+":")]),_vm._v("\n        "+_vm._s(_vm.sku)+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"amount"},[_vm._v("\n        "+_vm._s(_vm.$t('Quantity'))+":\n        "),_c('SfQuantitySelector',{model:{value:(_vm.form.qty),callback:function ($$v) {_vm.$set(_vm.form, "qty", $$v)},expression:"form.qty"}})],1)]),_vm._v(" "),_c('SfTextarea',{attrs:{"label":_vm.$t('Quote Comment')},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}}),_vm._v(" "),_c('SfButton',{attrs:{"type":"submit","disabled":invalid || _vm.loading}},[_c('SfLoader',{class:{ loader: _vm.loading },attrs:{"loading":_vm.loading}},[_c('div',[_vm._v(_vm._s(_vm.$t('Send')))])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }